<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="entrega/listar"
		:busca="{
            busca: busca.busca || null,
            status: busca.status || null,
            dtini: busca.dtinicio || null,
            dtfim: busca.dtfim || null,
            idfilial: busca.idfilial || null,
        }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            idsolicitacao: {nome: 'ID', tipo: permissao('entrega_get') ? 'link' : 'texto', link: '/entrega/get/'},
            idcliente: {nome: 'Cód. Cliente', tipo: 'texto'},
            cliente: {nome: 'Cliente', tipo: 'texto'},
            dtsolicitacao: {nome: 'Data solicitação', tipo: 'texto'},
            filial: {nome: 'Filial', tipo: 'texto'},
            status: {nome: 'Status', tipo: 'boolean', true: 'Entregue', false: 'Pendente', class: 'text-center'},
            cancelado: {nome: 'Cancelado', tipo: 'boolean', true: 'Sim', false: 'Não', class: 'text-center'},
        }"
	>
		<v-col class="d-none d-md-block pr-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
		</v-col>
		<v-col v-if="filtroEmpresa" class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="filiaisFiltro"
				item-text="filial"
				item-value="idfilial"
				label="Filial"
				outlined
				dense
				v-model="busca.idfilial"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="[
                        {value: 'F', text : 'Pendente'},
                        {value: 'T', text : 'Entregas gerada'},
                        {value: 'C', text : 'Cancelado'},
                    ]"
				label="Status"
				outlined
				dense
				v-model="busca.status"
			></v-select>
		</v-col>
		<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
			<v-sheet class="text-center" height="100%">
				<v-row class="pa-3">
					<v-col v-if="filtroEmpresa" cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="filiaisFiltro"
							item-text="filial"
							item-value="idfilial"
							label="Filial"
							outlined
							dense
							v-model="busca.idfilial"
						></v-select>
					</v-col>
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="[
                                    {value: 'F', text : 'Pendente'},
                                    {value: 'T', text : 'Entregas gerada'},
                                    {value: 'C', text : 'Cancelado'},
                                ]"
							label="Status"
							outlined
							dense
							v-model="busca.status"
						></v-select>
					</v-col>
					<v-col cols="6">
						<InputDatePicker
							v-model="busca.dtinicio"
							label="Data inicial"
							:outlined="true"
							:dense="true"
						/>
					</v-col>
					<v-col cols="6">
						<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="keydown = !keydown, sheet = !sheet">Filtrar</v-btn>
					</v-col>
				</v-row>
			</v-sheet>
		</v-bottom-sheet>
		<v-col class="pl-1">
			<v-text-field
				class="d-flex d-md-none"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				append-outer-icon="mdi-menu"
				@click:append-outer="sheet = !sheet"
				@keydown.enter="keydown = !keydown"
			/>
			<v-text-field
				class="d-none d-md-block"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import Table from "../Widgets/Table";

export default {
	name: "EntregaLista",
	mixins: [mixinFilial],
	components: { Table, InputDatePicker },
	data: () => ({
		sheet: false,
		keydown: false,
		filtroEmpresa: false,
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			status: null,
			idfilial: 0,
		},
	}),
	computed: {
		...mapState(["pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>